import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useBranchesQuery } from "../../services/branchApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { RxCross2 } from "react-icons/rx";
import { BookFormContext, FirstModalContext } from "../../App";
import {
  useCreateCodeMutation,
  useVerifyCodeMutation,
} from "../../services/phoneApi";
import { SuccessAlertFunction } from "../Common/SuccessAlert";
import { FailedAlertFunction } from "../Common/FailedAlertFunction";
import BackDropComponent from "../Common/BackDropComponent";
import { useNavigate } from "react-router-dom";
import { useAddLeadMutation } from "../../services/leadApi";

const phoneOptions = [
  {
    phone: "9989527715",
    branch: "Telangana & AP",
  },
  {
    phone: "8088837000",
    branch: "Karnataka",
  },
  {
    phone: "7847045678",
    branch: "Tamil Nadu",
  },
  {
    phone: "9154089451",
    branch: "For Kolkata ",
  },
];

const InQueryForm = ({ stylesClass, from, from1 }) => {
  const { setFirstModal } = useContext(FirstModalContext);
  const [phone, setPhone] = useState("");
  const { setBookForm } = useContext(BookFormContext);
  const { data } = useBranchesQuery({ page: "00", perPage: "00" });
  const [state, setState] = useState("Select State");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState("initial");
  const [code, setCode] = useState("");
  const [createCode] = useCreateCodeMutation();
  const [verifyCode] = useVerifyCodeMutation();
  const [showBackdrop, setBackdrop] = useState(false);
  const navigate = useNavigate();
  const [addLead] = useAddLeadMutation();

  const handleSelectState = (e) => {
    setState(e.target.value);
    const filter = data?.data?.filter((d) => d?.name === e.target.value);
    const sortedfilter = filter?.sort((a, b) => a.name.localeCompare(b.name));
    setCities(sortedfilter);
  };
  useEffect(() => {
    const uniqueData = data?.data?.filter(
      (item, index, self) =>
        self.findIndex((otherItem) => otherItem.name === item.name) === index
    );
    const sortedData = uniqueData?.sort((a, b) => a.name.localeCompare(b.name));
    setStates(sortedData);
  }, [data]);

  const handleCreateCode = (e) => {
    e.preventDefault();
    setBackdrop(true);
    createCode({ phone: phone }).then((res) => {
      if (res?.data?.status) {
        SuccessAlertFunction(res?.data?.message);
        setVerified("code");
        setBackdrop(false);
      } else {
        setBackdrop(false);
        FailedAlertFunction(res?.data?.message);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBackdrop(true);
    if (firstName && lastName && email && phone && state && city) {
      verifyCode({ phone: phone, code: parseInt(code) }).then(async (res) => {
        if (res?.data?.status) {
          const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            state: state,
            city: city,
            phone: phone,
            status: "pending",
          };
          addLead(data)
            .then((res) => {
              if (res.data?.status) {

                setTimeout(() => {
                  navigate("/thank-you");
                  setBackdrop(false);

                  setFirstName("");
                  setLastName("");
                  setEmail("");
                  setState("Select State");
                  setCity("");
                  setBookForm(false);
                }, 4000)

              } else {
                setBackdrop(false);

                FailedAlertFunction("Failed to submit the query!");
              }
            })
            .catch((error) => console.log(error));
        } else {
          if (res?.data?.message === "Expired!") {
            FailedAlertFunction("Code was expired! Please reverify Phone!");
            setVerified("initial");
            setCode("");
            setBackdrop(false);
          } else {
            setBackdrop(false);

            FailedAlertFunction(res?.data?.message);
          }
        }
      });
    } else {
      FailedAlertFunction("Please fill all the details!");
    }
  };

  const handleQuery = async (e) => {
    e.preventDefault();
    setBackdrop(true);
    if (firstName && lastName && email && phone && state && city) {
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        state: state,
        city: city,
        phone: phone,
        status: "pending",
      };
      addLead(data)
        .then((res) => {
          if (res.data?.status) {
            // SuccessAlertFunction(
            //   "Thanks for your Query. We will update you soon!"
            // );
            navigate("/thank-you");
            setBackdrop(false);

            setFirstName("");
            setLastName("");
            setEmail("");
            setState("Select State");
            setCity("");
            setBookForm(false);
          } else {
            setBackdrop(false);

            FailedAlertFunction("Failed to submit the query!");
          }
        })
        .catch((error) => console.log(error));
    } else {
      FailedAlertFunction("Please fill all the details!");
    }
  };

  return (
    <div
      className={` w-[90%] relative xl:absolute z-[1000] ${stylesClass} xl:top-[50%] translate-y-0 xl:translate-y-[-50%] bg-white lg:h-[100%] ${verified === "code" ? "xl:h-[540px]" : "xl:h-[490px]"
        } px-[1rem] mx-auto xl:px-[35px] py-[10px] lg:py-[22px] rounded-[.5rem] box-border shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:box-content`}
    >
      <h4 className="text-[#384f7e] text-[1.25rem] font-semibold">
        Enquiry Form
      </h4>
      <form className="w-[100%] flex flex-col items-center gap-[1rem] mt-[1rem]">
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <div className="w-[100%] grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 gap-[1rem]">
          <input
            className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="w-[100%] relative">
            <PhoneInput
              country={"in"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              width={"100%"}
              disabled={verified === "code" ? true : false}
            />
          </div>
        </div>
        <div className="w-[100%] grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 gap-[1rem]">
          <div class="relative inline-block w-[100%]">
            {from === "middle" && from1 === 'slide' ? (
              <select
                value={state}
                onChange={handleSelectState}
                class="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
              >
                <option value={"Select State"}>Select State</option>
                {states?.map((d) => (
                <option value={d?.name}>{d?.name}</option>
              ))}
                {/* <option value={"West Bengal"}>West Bengal</option> */}
              </select>
            ) : (
              <select
                value={state}
                onChange={handleSelectState}
                class="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
              >
                <option value={"Select State"}>Select State</option>
                {states?.map((d) => (
                  <option value={d?.name}>{d?.name}</option>
                ))}
              </select>
            )}
            <div class="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </div>
          <div class="relative inline-block w-[100%]">
            <select
              value={city}
              disabled={state === "Select State" ? true : false}
              onChange={(e) => setCity(e.target.value)}
              class="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
            >
              <option value={"Select City"}>Select City</option>
              {cities?.map((d) => (
                <option value={d?.city}>{d?.city}</option>
              ))}
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </div>
        </div>
        {from === "middle" ? (
          <>
            {verified === "code" && (
              <input
                className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
                type="text"
                placeholder="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            )}
            {verified === "initial" && (
              <button
                onClick={handleCreateCode}
                disabled={phone ? false : true}
                className="w-[100%] h-[40px] bg-[#eb4825] hover:bg-[#eb4825]  text-white uppercase rounded"
              >
                {phone ? "Verify Phone Number" : "Enquire Now"}
              </button>
            )}

            {verified === "code" && (
              <button
                onClick={handleSubmit}
                disabled={code ? false : true}
                className="w-[100%] h-[40px] bg-[#eb4825] hover:bg-[#eb4825]  text-white uppercase rounded"
              >
                Confirm
              </button>
            )}
          </>
        ) : (
          <>
            <button
              onClick={handleQuery}
              className="w-[100%] h-[40px] bg-[#eb4825] hover:bg-[#eb4825]  text-white uppercase rounded"
            >
              Submit
            </button>
          </>
        )}
        {/* // ) : (
        //   <button
        //     onClick={handleSubmit}
        //     className="w-[100%] h-[40px] bg-[#2796ac] hover:bg-[#34a9c0] text-white uppercase cursor-pointer rounded"
        //   >
        //     Enquiry Now
        //   </button>
        // )} */}
        {/* ///// CALL US OPTIONS ///// */}
        <div className="call_us_container flex justify-center text-center items-center px-[.3rem] w-[100%] h-[40px] border-[#eb4825] border-[1px] text-[#eb4825] hover:bg-[#eb4825] hover:border-white hover:text-white uppercase mb-[15px] rounded font-bold cursor-pointer relative">
          <p className="text-center">Call Us</p>
          <div className="inquery_phone_dropdown w-[100%] text-white absolute top-[100%] left-0 rounded-b-[6px]">
            {phoneOptions?.map((phone) => (
              <a
                className="w-[100%] bg-[#eb4825] hover:bg-[#eb4825] border-b-[1px] border-white px-[.5rem] py-[.3rem]"
                href="tel:+012345678"
              >
                <button>Call us {phone?.phone}</button>
              </a>
            ))}
          </div>
        </div>
      </form>
      {from === "middle" && (
        <RxCross2
          style={{ color: "grey" }}
          className="h-[20px] w-[20px] absolute top-[15px] right-[10px] cursor-pointer"
          onClick={() => {
            setFirstModal(false);
            setBookForm(false);
          }}
        />
      )}
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default InQueryForm;
